<!--
 * @Author: xiaoxiangliang
 * @Date: 2021-11-08 09:56:45
 * @LastEditTime: 2021-12-09 09:16:16
 * @LastEditors: xiaoxingliang
 * @FilePath: \lctmsFE4DS\src\components\LcSearch\index.vue
-->
<template>
  <el-form
    ref="lcSearch"
    v-bind="$attrs"
    class="lc-search"
    :model="form"
    size="small"
    :validate-on-rule-change="false"
    :label-width="labelWidth||'110px'"
    :label-position="labelPosition||'right'"
  >
    <div class="search-content">
      <div class="search-area" v-if="areaFieldList.length">
        <el-row>
          <el-col :span="24">
            <div class="search-id">
              <template v-if="areaFieldList.length === 1">
                <label :style="{ width: labelWidth||'110px' }">
                  <span>
                    {{areaFieldList[0].label}}
                  </span>
                </label>
                <el-input
                  v-model.trim="form[areaFieldList[0].value]"
                  type="textarea"
                  placeholder="支持批量搜索，多单号用逗号分隔"
                  @blur="handleTextAreaContent(form)"
                ></el-input>

              </template>
              <template v-else>
                <label :style="{ width: labelWidth||'110px' }">
                  <el-select
                    v-model="form.billLabel"
                    class="muitl-select"
                    @change="billLabelChange"
                    size="mini"
                    filterable
                  >
                    <option
                      v-for="(opts,index) in areaFieldList"
                      :key="index"
                      :value="opts.value"
                    >{{opts.label}}</option>
                  </el-select>
                </label>
                <el-input
                  v-model.trim="formData.billValue"
                  type="textarea"
                  placeholder="支持批量搜索，多单号用逗号分隔"
                  @blur="handleTextAreaContent(form)"
                  ></el-input>
              </template>

            </div>
          </el-col>
        </el-row>
      </div>
      <div class="group-item">
        <el-row class="form-row" :gutter="30">
          <template v-for="(col,index) in fieldList">
            <el-col
              v-if="!(col.multiple && col.searchType === 'input')"
              :key="`${col.field}_${index}`"
              :span="24/colSize"
              class="form-col"
            >
              <template>
                <el-form-item
                  :label="col.title"
                  :prop="col.field"
                  :label-width="col.labelWidth||'110px'"
                  class="form-item"
                >
                  <!-- <el-input v-model="form[col.field]"></el-input> -->
                  <RenderSearch
                    v-bind="col"
                    v-model="form[col.field]"
                    :compis="col.searchType"
                    v-on="col.on"
                  />
                </el-form-item>
              </template>
            </el-col>
          </template>
          <el-col :span="2">
            <el-form-item :label-width="labelWidth||'110px'">
              <el-button @click="handleSearch" type="primary" plain size="small" icon="el-icon-search">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-form>
</template>

<script>
import RenderSearch from './RenderSearch'
export default {
  components: {
    RenderSearch,
  },
  props: {
    formData: {
      type: Object,
    },
    labelWidth: {
      type: String,
    },
    labelPosition: {
      type: String,
    },
    colSize: {
      type: [Number, String],
      default: 4,
    },
    fieldList: {
      type: Array,
    },
  },
  data() {
    return {
      areaValue: '', //单号texarea的值
      areaData: [], //订单号搜索区域的[key,value]map
      // form: {
      //   billLabel: '',
      //   billValue: '',
      // },
    }
  },
  computed: {
    //需要做多单查询的字段
    areaFieldList() {
      return this.fieldList
        .filter(item => item.searchType === 'input' && item.multiple) // 筛选text输入框
        .map((ops) => {
          return { label: ops.title, value: ops.field }
        })
    },
    form: {
      get() {
        return this.formData
      },
      set(val) {
        this.$emit("update:formData", val)
      }
    }
  },
  watch: {
    // formData: {
    //   handler(newVal) {
    //     if (this.areaFieldList.some((item) => item.value == newVal.billLabel)) {
    //       this.form = { ...this.form, ...newVal }
    //     } else {
    //       this.form = {
    //         ...newVal,
    //         billLabel: this.form.billLabel,
    //         billValue: this.form.billValue,
    //       }
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    areaFieldList: {
      handler(newVal) {
        console.log('监听区域值变化172行')
        if (!newVal.length) return
        //如果存在批量搜索的区域字段，默认把第一项给选中
        this.form.billLabel = this.areaFieldList[0].value
      },
      deep: true,
    },
  },

  created() {
  },
  mounted() {},
  methods: {
    handleChangeFormData(newVal) {
      console.log(newVal, this.form)
      this.$emit("update:formData", this.form)
    },
    //多单查询切换label清空输入框的值
    billLabelChange() {
      this.form.billValue = ''
    },
    handleSearch() {
      //如果存在area字段，就把对应的key和value生成一个对象
      // let obj = {}
      // if (this.areaFieldList.length) {
      //   obj[this.form.billLabel] = this.form.billValue
      //     ? this.form.billValue.replace(/\s+/g, ',')
      //     : ''
      // }
      // let form = { ...this.form, ...obj }
      // console.log(form)
      console.log('lcSearch页面207行')
      this.$emit('formParams', { ...this.form })
    },
    handleTextAreaContent(textAreaContent) {
      // 文本域的属性名
      let label = textAreaContent.billLabel
      // 文本域的属性值
      let value  = textAreaContent[label]
      console.log(label + '：' + value)
      console.log('218行，搜索区域处理批量查询单号方法' + JSON.stringify(textAreaContent))
    },
  },
}
</script>

<style lang="scss" scoped>
.lc-search {
  flex-shrink: 0;
  background-color: #fff;
  margin-bottom: 5px;
  .search-content {
    width: 98%;
    display: flex;
    padding: 10px 10px 0 10px;
    .search-area {
      width: 250px;
      min-width: 250px;
      box-sizing: border-box;
      ::v-deep .el-row {
        height: 100%;
        .el-col {
          height: 100%;
          .search-id {
            height: 100%;
            display: flex;
            label {
              height: 32px;
              display: flex;
              align-items: center;
              font-size: 14px;
              padding-right: 12px;
              select {
                width: 90px;
                color: #606266;
                font-weight: 500;
                outline: none;
              }
            }
            .el-textarea {
              padding-bottom: 18px;
              .el-textarea__inner {
                height: 100%;
              }
            }
          }
          // .el-form-item {
          //     margin-bottom: 0;
          //     height: 100%;
          //     .el-form-item__content{
          //         height: 100%;
          //         .el-textarea{
          //             height: 100%;
          //             .el-textarea__inner{
          //                 height: 100%;
          //             }
          //         }
          //     }
          // }
        }
      }
    }
    .group-item {
      flex: 1;
      box-sizing: border-box;
      ::v-deep .el-row {
        .el-col {
          height: 50px;
          // display: flex;
          // align-items: center;
          .el-form-item {
            width: 100%;
            margin-bottom: 0;
            .el-form-item__label {
              font-size: 12px;
              color: #333;
              padding: 0 6px 0 0;
            }
            .el-form-item__content {
              .el-select {
                width: 100%;
              }
              .el-range-editor {
                width: 100%;
              }
              .el-date-editor {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>



