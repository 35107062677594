<template>
  <div class="search-table" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
    <LcSearch
      v-if="searchFields.length !== 0"
      :labelWidth="labelWidth"
      :fieldList="searchFields"
      :formData.sync="formData"
      :colSize="columnSize"
      @formParams="formParams"
    />
    <!-- <div class="divided-line"></div> -->
    <!-- 自定义表格上面显示 -->
    <slot name="table-top"></slot>
    <LcTable
      v-model="tableParams"
      :autoBtnList="autoBtnList"
      :fixedBtnList="fixedBtnList"
      :colSize="columnSize"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :searchFields="searchFields"
      :sortFields="computedSortFields"
      :sortAllFields="sortAllFields"
      :checkboxColumn="checkboxColumn"
      :hasPagination="hasPagination"
      :showFooter="showFooter"
      :footerData="footerData"
      :formData.sync="formData"
      :pageSizes="pageSizes"
      :showSetting="showSetting"
      :searchFieldSetting="searchFieldSetting"
      :showColumnSearch="showColumnSearch"
      :showSortable="showSortable"
      :showTableHeader="showTableHeader"
      :optionColumn="optionColumn"
      @tableHeaderSearch="tableHeaderSearch"
      @selectChange="selectChange"
      @headerWidthChange="headerWidthChange"
      @emitSearchList="emitSearchList"
      @emitTableList="emitTableList"
      @emitSortList="emitSortList"
      @sortMethod="sortMethod"
      @optionColumnHandle="optionColumnHandle"
    >
      <!-- 自定义操作列的渲染内容 -->
      <!-- <template v-slot:fixed="scope">
        <span :style="{ color: scope.column.textColor }">
          <span>修改</span>
          <span>删除</span>
        </span>
      </template> -->
      <!-- 自定义操作栏 -->
      <template v-slot:operate>
        <slot name="operate"></slot>
      </template>
      <!-- 自定义普通列内容 -->
      <template v-slot:content="scope">
        <span
          v-if="scope.column.renderType == 'achnor'"
          :style="{ color: scope.column.textColor, cursor: 'pointer' }"
          @click.stop="fieldOperateList[scope.column.field](scope.row, scope.column)"
          >{{ scope.row[scope.column.field] }}</span
        >
        <span
            v-else-if="scope.column.renderType == 'image' ">
        <viewer v-if=" scope.row[scope.column.field]">
          <img class="img" :src=" scope.row[scope.column.field]" />
        </viewer>

        </span>
        <span
            v-else-if="scope.column.renderType == 'imageArr' ">
        <viewer v-if=" scope.row[scope.column.field]">
          <img class="img" :src="JSON.parse(scope.row[scope.column.field])[0].url" />
        </viewer>
        </span>
        <span
            v-else-if="scope.column.renderType == 'date' ">
           {{dateFormat( scope.row[scope.column.field]) }}
        </span>

        <span
          v-else-if="scope.column.renderType == 'status' && scope.column.tag == false"
          :style="{ color: scope.column.textColor }"
          @click.stop="scope.column.clickable && fieldOperateList[scope.column.field](scope.row, scope.column)"
        >
          {{ scope.row[scope.column.field] }}
        </span>

        <span
          v-else-if="scope.column.renderType == 'status' && scope.column.enum == true"
          :style="{ color: scope.column.textColor }"
          @click.stop="scope.column.clickable && fieldOperateList[scope.column.field](scope.row, scope.column)"
        >
          <el-tag size="mini" :type="getTagType(scope.column.fieldOperateTag, scope.row[scope.column.field])">{{
            getTagValue(scope.column.fieldEnumValueList,scope.row[scope.column.field])
          }}</el-tag>
        </span>

        <div
          style="padding: 0 10px"
          v-else-if="scope.column.renderType == 'progress'"
          @click.stop="scope.column.clickable && fieldOperateList[scope.column.field](scope.row, scope.column)"
        >
          <el-progress :percentage="scope.row[scope.column.field]" :color="customColors"></el-progress>
        </div>

        <!-- <span v-else>{{scope.row[scope.column.field] == 0 ? null : scope.row[scope.column.field] }}</span> -->
        <span v-else>{{ formatterRowValue(scope.column, scope.row, scope.$index) }}</span>
      </template>
    </LcTable>
  </div>
</template>

<script>
import LcSearch from '@/components/LcSearch'
import LcTable from '@/components/LcTable'
import {formatDate} from "@/utils/time";
export default {
  name: 'SearchTable',
  components: {
    LcSearch,
    LcTable
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    // form的label
    labelWidth: {
      type: String,
      default: '110px'
    },
    // 搜索表单的配置项
    searchFields: {
      type: Array,
      default: () => []
    },
    colSize: {
      type: [String, Number],
      default: 4
    },
    // 默认初始化数据
    initFormData: {
      type: Object,
      default() {
        return {}
      }
    },
    // 处理搜索的数据
    proxyParams: {
      type: Function
    },

    // 表格基础配置项
    baseColumns: {
      type: Array,
      default: () => []
    },
    // 表格配置项
    tableColumns: {
      type: Array,
      default: () => []
    },
    // 排序设置的配置项
    sortFields: {
      type: Array,
      default: () => []
    },
    // 操作的按钮列表
    autoBtnList: {
      type: Array,
      default: () => []
    },
    // 操作的按钮列表
    fixedBtnList: {
      type: Array,
      default: () => []
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => []
    },
    // 表格勾选框
    checkboxColumn: {
      type: Boolean,
      default: true
    },
    // 分页器
    hasPagination: {
      type: Boolean,
      default: true
    },
    // 是否显示表尾合计
    showFooter: {
      type: Boolean,
      default: true
    },
    // // 表格合计数据
    // footerData: {
    //   type: Array,
    //   default: () => []
    // },

    // 后端返回的总合计数据
    tableSummary: {
      type: Object
    },

    // // 获取表格数据
    // getTableData: {
    //   type: Function
    // },
    total: {
      type: [String, Number],
      default: 0
    },
    // 分页
    pageSizes: {
      type: Array,
      default() {
        return [100, 500, 1000]
      }
    },
    // 需要选中合计的字段集
    selectTableSummary: {
      type: Array,
      default: () => []
    },
    // 表格单元格操作列表, achnor形式 跳转
    fieldOperateList: {
      type: Object,
      default() {
        return {}
      }
    },
    // 搜索条件设置的配置，用于处理特殊情况下的搜索条件不变动的问题
    searchFieldSetting: {
      type: Boolean,
      default: true
    },
    // 表头和搜索项的设置的配置
    showSetting: {
      type: Boolean,
      default: true
    },
    // 表单表头的搜索
    showColumnSearch: {
      type: Boolean,
      default: true
    },
    // 表头排序
    showSortable: {
      type: Boolean,
      default: true
    },
    // 表格头部
    showTableHeader: {
      type: Boolean,
      default: true
    },
    optionColumn: {
      type: Object
    }
  },
  data() {
    return {
      formData: {
        billLabel: '',
        billValue: ''
      }, // 搜素form的数据
      tableParams: {
        size: this.pageSizes[0],
        current: 1,
        total: 0,
        orderField: null, //排序的字段名
        orderRule: null //排序的方式
      },
      columnSize: 4,
      // 全部合计
      allSummary: [],
      footerData: [],
      // 选中合计
      selectSummary: [],
      selectTableData: [], //checkbox选中的表格数据
      filtersNormal: [
        // columnType为normal的筛选值
        {
          label: '所有',
          value: undefined,
          checked: true
        },
        {
          label: '空值',
          value: 'null'
        },
        {
          label: '非空值',
          value: 'nonNull'
        },
        {
          label: '自定义',
          value: 'custom',
          data: ''
        }
      ],
      customColors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ]
    }
  },
  computed: {
    // 排序设置
    sortAllFields() {
      const sortFields = []
      this.tableColumns
        .filter(v => v.sortable)
        .map((item, index) => {
          sortFields.push({
            keyId: item.keyId + '-1',
            title: item.title + '升序',
            field: item.field + '-asc'
          })
          sortFields.push({
            keyId: item.keyId + '-2',
            title: item.title + '降序',
            field: item.field + '-desc'
          })
        })
      // console.log(sortFields)
      return sortFields
    },
    computedSortFields() {
      if (this.sortFields.length === 0) {
        return this.sortAllFields
      }
      return this.sortFields
    }
  },
  watch: {
    'tableParams.size': {
      handler() {
        // this.getTableData()
        this.$emit('getTableData', { ...this.tableParams, ...this.formData })
      }
    },
    'tableParams.current': {
      handler() {
        // this.getTableData()
        this.$emit('getTableData', { ...this.tableParams, ...this.formData })
      }
    },

    // 排列行
    colSize: {
      handler(newVal) {
        this.columnSize = newVal
      },
      immediate: true
    },
    // 处理父组件接口返回的total
    total: {
      handler(newVal) {
        this.tableParams.total = newVal
      },
      immediate: true
    },
    //监听表格总合计数据
    tableSummary: {
      handler(newVal) {
        let arr = ['合计']
        if (this.checkboxColumn) {
          arr.push(this.total)
        }
        // newVal.index > 0 ? arr.push(newVal.index) : arr.push('')
        this.tableColumns.map((item, index) => {
          if (newVal && newVal.hasOwnProperty(item.field)) {
            if (item.field === 'index') {
              arr.push(newVal.index)
            } else {
              arr.push(newVal[item.field])
            }
          } else {
            arr.push('')
          }
        })
        this.allSummary = [...arr]
        this.footerData = [arr]
      },
      deep: true,
      immediate: true
    },
    //监听选中表格数据变化
    selectTableData: {
      handler(newVal) {
        let arr = ['选中合计']
        if (this.checkboxColumn) {
          arr.push(newVal.length)
        }
        // newVal.index > 0 ? arr.push(newVal.index) : arr.push('')
        const selectTableSummary = this.tableColumns.filter(v => v.summable).map(item => item.field)
        let obj = this.getSummaries(
          this.tableColumns.filter(v => v.summable),
          newVal
        )
        this.tableColumns.map(item => {
          if (selectTableSummary.includes(item.field)) {
            arr.push(obj[item.field])
          } else {
            arr.push('')
          }
        })
        this.selectSummary = [...arr]
        newVal.length ? (this.footerData = [this.selectSummary, this.allSummary]) : (this.footerData = [this.allSummary])
      },
      deep: true
    }
  },
  async created() {
    // this.columnSize = this.colSize
    await this.$nextTick()
    this.formData = this.initFormData
    this.$emit('getTableData', { ...this.tableParams, ...this.formData })
  },
  mounted() {},
  methods: {
    // 获取tag标签类型
    getTagType(operationList, value) {
      if(operationList==undefined||operationList==null) {
        return 'info'
      } else {
        return operationList[value]
      }
    },

    dateFormat(val){
      if(val){
        const dateT = new Date(val)
        const y = dateT.getFullYear()
        let MM = dateT.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = dateT.getDate()
        d = d < 10 ? ('0' + d) : d
        return y + '-' + MM + '-' + d
      }else {
        return ''
      }
    },

    // 获取标签值
     getTagValue(operationList, value) {
        if(operationList==undefined||operationList==null) {
        return null
      } else {
        return operationList[value]
      }
    },
    //表格列排序方法
    sortMethod({ orderField, orderRule }) {
      console.log(orderField, orderRule)
      if (orderRule) {
        this.tableParams.orderField = orderField
        this.tableParams.orderRule = orderRule
      } else {
        this.tableParams.orderField = null
        this.tableParams.orderRule = null
      }
      // this.getTableData()
      this.$emit('getTableData', { ...this.tableParams, ...this.formData })
    },
    // 自定义操作列点击事件  ys添加 2022-06-09
    optionColumnHandle(row,event) {
      this.$emit(event, { ...row })
    },
    //配置search字段弹窗
    emitSearchList({ colSize, list }) {
      console.log(colSize)
      // this.searchFields = [...list]
      this.columnSize = colSize
      console.log('配置字段发生了变化410行')
      this.$emit('saveSearchField', list, colSize)
    },
    //配置table字段弹窗
    emitTableList(data) {
      console.log('415配置table字段弹窗' + this.tableColumns)
      // this.tableColumns = [...data]
      let keyIds = data.map(item => item.field)
      //如果search字段里面在表格列中找不到匹配的字段，就把这个搜索条件删除
      let arr = []
      this.searchFields.map(item => {
        if (keyIds.indexOf(item.field) > -1) {
          arr.push(item)
        }
      })
      // this.searchFields = [...arr]
      //调整列的配置之后重新请求表格数据，防止合计列没有对应上
      // this.getTableData()
      // this.$emit('getTableData', { ...this.tableParams, ...this.formData })
      this.$emit('saveTableField', data) // 保存|修改表格头配置
      // this.$emit('saveSearchField', arr) // 保存|修改搜索字段配置
    },
    //配置table里sort排序字段弹窗
    emitSortList(data) {
      let keyIds = data.map(item => item.field)
      // 如果字段里面在表格列中找不到匹配的字段，就把这个搜索条件删除
      let arr = []
      this.sortAllFields.map(item => {
        if (keyIds.indexOf(item.field) > -1) {
          arr.push(item)
        }
      })
      //调整列的配置之后重新请求表格数据，防止合计列没有对应上
      this.$emit('saveSortField', arr) // 保存|修改搜索字段配置
    },
    //表格选中checkbox触发的change事件
    selectChange(selectData) {
      this.selectTableData = selectData
    },
    //拿到表格头触发搜索后的属性和值
    tableHeaderSearch({ value, column }) {
      // this.formData = {...this.formData}
      console.log('表头搜索发生变化了453行')
      if (column.multiField) {
        this.formData = {
          billLabel: column.field,
          billValue: value
        }
      }
      let obj = {}
      obj[column.field] = value
      this.formData = { ...this.formData, ...obj }
      console.log('this.formData', this.formData)
      if (value) {
        //todos:调取查询接口
        // this.getTableData()
        this.$emit('getTableData', { ...this.tableParams, ...this.formData })
      }
    },
    //接收search,emit的出来的搜索条件数据
    formParams(params) {
      this.formData = { ...params }
      // todo :调取查询接口 调用接口前处理参数
      // this.getTableData()
      this.tableColumns.forEach(column => {
        const field = column.field
        if (column.searchType === 'input' && column.multiple) {
          if (params[field]) {
            params[field] = params[field].split(',')
          }
        }
      })
      this.$emit('getTableData', { ...this.tableParams, ...params })
    },
    // 计算选中合计的每一列数据的和  只计算summable为true的值
    getSummaries(columns, data) {
      let obj = {}
      // console.log(columns)
      columns.map(col => {
        if (col.field) {
          const values = data.map(item => Number(item[col.field]))
          if (!values.every(value => isNaN(value))) {
            obj[col.field] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                //精确到小数点后4位
                return Math.round((prev + curr) * 10000) / 10000
              } else {
                return prev
              }
            }, 0)
          } else {
            obj[col.field] = ''
          }
        }
      })
      return obj
    },
    //表格列宽发生变化
    headerWidthChange({ property, title, resizeWidth }) {
      this.tableColumns.forEach(item => {
        if (item.field == property) {
          item.width = resizeWidth
        }
      })
      //todos:请求更新表头接口
      this.$emit('saveTableField', this.tableColumns)
      // this.saveTableField(this.tableColumns)
    },
    // 给table设置默认值
    formatterRowValue(column, row, $index) {
      const txt = column.formatter ? column.formatter(row[column.field], $index, row) : row[column.field]
      return this.isEmpty(txt) ? null : txt
    },
    isEmpty(txt) {
      return txt === '' || txt === undefined || txt === null
    }
  }
}
</script>

<style lang="scss" scoped>
.search-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  .divided-line {
    height: 5px;
    background-color: #cccccc;
    flex-shrink: 0;
  }

  .img {
    width: 20px;
    border-radius: 4px;
    height: 20px;
  }
}
</style>
