<!--
 * @Author: xiaoxiangliang
 * @Date: 2021-10-23 09:26:41
 * @LastEditTime: 2021-12-08 17:26:03
 * @LastEditors: xiaoxingliang
 * @FilePath: \lctmsFE4DS\src\components\LcTable\SetTableField.vue
-->
<template>
  <el-dialog
    class="set-table-header"
    :visible="dialogVisible"
    title="表格字段设置"
    width="700px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
  >
    <div class="content">
      <div class="left">
        <span class="title">可选择字段:{{allHeadersTotal}}</span>
        <div class="left-headers">
          <ul>
            <li
              v-for="(header,index) in allFieldList"
              :key="header.field"
              @click="leftListItemClick(header,index)"
            >
              <span>{{header.title}}</span>
              <i v-show="header.checked" class="el-icon-check"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="middle">
        <i class="el-icon-arrow-right"></i>
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="right">
        <span class="title">已选择字段:{{hasCheckedTotal}}</span>
        <div class="right-headers">
          <ul id="setting" ref="setting">
            <template v-for="(header,index) in checkedFieldList">
              <!-- 绑定的key值必须为数据里的唯一值，不能绑定index是考虑到diff算法的原因 -->
              <li :key="header.field">
                <span>{{header.title}}</span>
                <i class="el-icon-close" @click="rightListItemClick(header,index)"></i>
              </li>
            </template>
          </ul>
        </div>
        <span class="tips">最多可选50个字段</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button plain size="mini" @click="handleClose">取 消</el-button>
      <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Sortable from 'sortablejs'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    //自定义字段配置
    provideTableConfig: {
      type: Array,
    },
    //初始化的全部字段配置
    baseConfig: {
      type: Array,
    },
    //搜索字段展示列数
    colSize: {
      type: Number,
    },
  },
  data() {
    return {
      //全部的字段数据
      allFieldList: [...this.baseConfig],
      //选中的字段数据
      checkedFieldList: [...this.provideTableConfig],
      searchRadio: this.colSize || 4,
    }
  },
  computed: {
    allHeadersTotal() {
      return this.allFieldList.length
    },
    hasCheckedTotal() {
      return this.checkedFieldList.length
    },
  },
  watch: {
    // provideTableConfig: {
    //   handler(newVal) {
    //     // if (!newVal.length) return
    //     let keyIdList = newVal.map((item) => {
    //       this.checkedFieldList.push({ ...item })
    //       return item.field
    //     })
    //     this.allFieldList.forEach((el) => {
    //       if (keyIdList.indexOf(el.field) > -1) {
    //         el.checked = true
    //       } else {
    //         el.checked = false
    //       }
    //     })
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    checkedFieldList: {
      handler(newVal) {
        // if (!newVal.length) return
        let keyIdList = newVal.map((item) => item.field)
        this.allFieldList.forEach((el) => {
          if (keyIdList.indexOf(el.field) > -1) {
            el.checked = true
          } else {
            el.checked = false
          }
        })
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    //左侧list点击事件
    leftListItemClick(header, index) {
      if (header.checked) {
        this.allFieldList[index].checked = false
        this.checkedFieldList.map((item, ind) => {
          if (item.field == header.field) {
            this.checkedFieldList.splice(ind, 1)
          }
        })
      } else {
        this.allFieldList[index].checked = true
        this.checkedFieldList.push({ ...header })
      }
    },
    //右侧list点击事件
    rightListItemClick(header, index) {
      this.checkedFieldList.splice(index, 1)
    },
    //提交
    handleSubmit() {
      if (this.hasCheckedTotal > 50) {
        this.$message.error('最多可选50个字段!')
        return
      }
      if (this.hasCheckedTotal < 1) {
        this.$message.error('最少选择一个字段!')
        return
      }
      this.$emit('emitTableList', [...this.checkedFieldList])
      this.handleClose()
    },
    //关闭弹窗
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
    //行拖拽
    rowDrop() {
      const headerBody = document.getElementById('setting')
      const _this = this
      if (!headerBody) return //表格第一次渲染时回获取不到dom节点
      Sortable.create(headerBody, {
        onEnd({ newIndex, oldIndex }) {
          let currRow = _this.checkedFieldList.splice(oldIndex, 1)[0]
          _this.checkedFieldList.splice(newIndex, 0, currRow)
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.set-table-header {
  ::v-deep .el-dialog {
    .el-dialog__header {
      border-bottom: 1px solid #f2f2f2;
    }
    .el-dialog__body {
      padding: 0 20px;
      .content {
        width: 100%;
        height: 500px;
        display: flex;
        .title {
          display: block;
          width: 300px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #606266;
          font-weight: normal;
          margin: 0;
        }
        .left {
          width: 300px;
          height: 100%;
          .left-headers {
            height: 440px;
            border: 1px solid #f2f2f2;
            border-radius: 5px;
            overflow: hidden;
            overflow-y: scroll;
            ul {
              padding: 0;
              li {
                width: 100%;
                height: 30px;
                line-height: 30px;
                list-style: none;
                padding: 0 20px;
                display: flex;
                cursor: pointer;
                span {
                  flex: 1;
                }
                i {
                  display: block;
                  width: 30px;
                  height: 30px;
                  text-align: center;
                  line-height: 30px;
                  font-size: 18px;
                  color: green;
                }
              }
              li:hover {
                background-color: #f5f5f5;
              }
            }
          }
        }
        .middle {
          width: 60px;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          i {
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 22px;
            text-align: center;
            color: #999;
          }
        }
        .right {
          width: 300px;
          height: 100%;
          .right-headers {
            height: 440px;
            border: 1px solid #f2f2f2;
            border-radius: 5px;
            overflow: hidden;
            overflow-y: scroll;
            ul {
              padding: 0;
              li {
                width: 100%;
                height: 30px;
                line-height: 30px;
                list-style: none;
                padding: 0 20px;
                display: flex;
                span {
                  flex: 1;
                }
                i {
                  display: block;
                  width: 30px;
                  height: 30px;
                  text-align: center;
                  line-height: 30px;
                  font-size: 18px;
                  color: #999;
                }
                i:hover {
                  color: #dc0000;
                }
              }
              li:hover {
                background-color: #f5f5f5;
                cursor: pointer;
              }
            }
          }
          .tips {
            display: block;
            width: 100%;
            height: 30px;
            line-height: 30px;
            color: #dc0000;
            font-size: 12px;
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 0 20px;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid #f2f2f2;
      .dialog-footer {
        button {
          width: 60px;
          height: 30px;
          margin-left: 10px;
          cursor: pointer;
        }
        .cancel {
          border: 1px solid #409eff;
          background-color: #fff;
          color: #409eff;
          border-radius: 4px;
          font-size: 14px;
        }
        .submit {
          border: 1px solid #409eff;
          background-color: #409eff;
          color: #fff;
          border-radius: 4px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>





